import React, { useState } from 'react';
import NewHeader from './NewHeader';
import Sidebar from './Sidebar';
import './AccountSettings.css';

function AccountSettings() {
  const stylization = {
    fontSize : '18px',
  }
  const cookieData = document.cookie
  const sessionData = cookieData.split(',');

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://e-lectsg.com/forgot-password/', {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (data.message) {
          setMessage(data.message);
        } else {
          print('Error: Something went wrong, please try again.');
        }
      } else {
        setMessage(data.message || 'Error: Something went wrong, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Something went wrong. Please reach out to IT staff.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="voter-app-container">
      <NewHeader />
      <div className="voter-main-content">
        <Sidebar />
        <div className="voter-content">
          <div className="voter-account-section">
            <h2>Account Settings</h2>
            <div className="voter-account-details">
              <div className='info'><span style={stylization}>Name: {sessionData[2]} {sessionData[3]}</span></div>
              <div className='info'><span style={stylization}>Username: {sessionData[1]}</span></div>
              <div className='info'><span style={stylization}>Department: {sessionData[4]}</span></div>
            </div>
            <button
              className="voter-reset-button"
              onClick={handleResetPassword}
              disabled={isLoading}
            >
              {isLoading ? 'Resetting...' : 'Reset Password'}
            </button>
            {message && <p className="reset-message">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
