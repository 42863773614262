import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://e-lectsg.com/forgot-password/', {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (data.message) {
          setMessage(data.message);

          if (data.message === "A new password has been sent to your email address. You will be redirected to the homepage shortly.") {
            setTimeout(() => {
              navigate('/');
            }, 3000);
          }
        } else {
          setMessage('Error: Something went wrong, please try again.');
        }
      } else {
        setMessage('Error: Something went wrong, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Something went wrong. Please reach out to IT staff.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <h1>Forgot Password</h1>
      <form onSubmit={handleForgotPassword}>
        <label htmlFor="email">Email Address:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isLoading}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Sending...' : 'Send New Password'}
        </button>
      </form>
      <p>{message}</p>
    </div>
  );
}

export default ForgotPassword;